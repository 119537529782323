<template>
  <div class="puzzle" :style="{ backgroundImage: bgControllerUrl }">

    <section
    
    class="waiting section p-0 is-flex is-flex-direction-column has-background-white-ter has-text-black-bis" v-if="serverState < 2">
      
        <div class="top p-5">
          <div>
            <div class="has-text-weight-bold is-size-5 pb-3">{{ $t('exp_puzzle.howToPlay') }}</div>
            <p class="is-size-6">{{ $t('exp_puzzle.assembleThePuzzle') }}</p>
            <div class="tutorial py-5 is-flex is-justify-content-center">
              <img src="/assets/puzzle/tutorial.png" :alt="$t('exp_puzzle.howToPlay')" class="image" />
            </div>
            <p v-if="options.tutorialExtraText" class="mt-2">{{options.tutorialExtraText}}</p>
          </div>
          <div class="playerlist px-3">
              <player-avatar v-for="player in playerListIntro"
                :player="player"
                :key="player.id"
                :scale="1.8"
                :showUsername="true"
                :horizontal="true"
                class="mb-2"/>
         </div>
        </div>
        <div v-if="moreOnePlayer" class="bottom is-flex is-align-items-center is-justify-content-center">
          <span class="is-size-5">{{ $t('exp_puzzle.waitingPlayers') }}</span>
        </div>
    </section>

    <!-- PUZZLE -->
    <section class="puzzleSection section p-0" v-if="serverState == 2">
      <div id="imagenPuzzleContainer" ref="imagenPuzzleContainer">
        <img :src="currentPuzzleImage" :style="{width: `${imageCrop.width}px`, height: `${imageCrop.height}px`, objectPosition: imageCrop.objectPosition}"/>
        <div v-if="round > 0" class="top-gameUI p-3 pb-4">
          <div>{{ $t('exp_puzzle.roundNumber',[round,maxRounds])}}</div>

          <b-progress type="is-danger" size="is-large" show-value :value="gameTimer.normalized">{{ gameTimer.string }}</b-progress>

        </div>
          
        <div class="bottom-gameUI">
  
          <div v-if="(gameSubState == SUBSTATES.roundWin)" class="badge round-win">
            <d-icon pack="fas" icon="check" size="is-large"/>
          </div>

          <div v-else-if="failed && gameSubState == SUBSTATES.attempting" class="badge failed">
            <d-icon pack="fas" icon="xmark" size="is-large"/>
          </div>

         <div v-else :class="{
            disabled: DisableSyncButton,
            pressed: playerAttempted}" @click="sync">

            <progress-circle
                :diameter="90"
                :circle-width="6"
                :completed-steps="attemptCircle"
                :total-steps="100"
                circle-color="#DBDBDB"
                start-color="var(--timer-fill-start)"
                stop-color="var(--timer-fill-stop)"
                inner-color="var(--timer-inner)"
                :animation-duration="10">
                  <template v-if="playerAttempted">
                    <span class="has-text-weight-bold is-size-3">
                    {{  playerAttemptIndex + 1  }}
                    </span>
                  </template>
                  <template v-else>
                    <d-icon pack="fas" icon="puzzle-piece-simple" size="is-large" class="is-primary"/>
                  </template>
              </progress-circle>
          </div>
          
        </div>
      </div>
      
    </section>

    <!-- WIN / LOSE -->
    <section
      class="puzzleSection match-finished section"
      v-if="serverState == 3">
        <div v-if="gameSubState == SUBSTATES.win">
          <div class="is-size-2">
            <svg class="win" viewBox="0 0 512 512">
                  <path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
            </svg>
          </div>
          <div class="is-size-3 has-text-weight-bold">
             {{ $t('exp_trivia.youWin') }}
          </div>
        </div>

        <div v-if="gameSubState == SUBSTATES.lose">
          <div class="is-size-2">
            <svg class="lose" viewBox="0 0 320 512">
                  <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
            </svg>
          </div>
          <div class="is-size-3 has-text-weight-bold">
             {{ $t('exp_trivia.youLost') }}
          </div>
        </div>

        <div class="auto-height">
          <b-button type="is-primary" :label="$t('system.backToMenu')" expanded @click="$router.push('/')" />
        </div>

    </section>
    
  </div>
</template>

<script>
import PlayerAvatar from '../PlayerAvatar.vue'
import { ProgressCircle } from 'vue-progress-circle'

export default {
  components: {
    PlayerAvatar,
    ProgressCircle
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      myPosition: -1,
      SUBSTATES: Object.freeze({
        tutorial: 0,
        playing: 1,
        attempting: 2,
        roundWin: 3,
        win: 4,
        lose: 5}
      ),
      playerOrder: [],
      playersAttempt: [],
      round: 0,
      maxRounds: 0,
      tsWillFinish: 0,
      timerWebWorkers: [],
      timerCircle: 100,
      attemptWebWorkers: [],
      attemptCircle: 100,
      failed: false,
      gameTimer: {
        normalized: 100,
        string: ''
      }
    };
  },
  computed: {
    serverState() {
      return this.$parent.serverState
    },
    gameSubState() {
      return this.$parent.gameSubState
    },
    gameSubStateName() {
      return  Object.keys(this.SUBSTATES)[this.gameSubState]
    },
    moreOnePlayer(){
      return this.$parent.room.experience.component[0].queue.maxPlayers > 1
    },
    players() {
      return this.$store.state.user.players
    },
    playerListIntro(){
      return this.$store.state.user.players
        .filter(x => x.dbid != this.$store.state.user.profile.id)
    },
    myId(){
      return this.$store.state.user.profile.id
    },
    DisableSyncButton(){
      if(this.gameSubState == this.SUBSTATES.tutorial) return true 

      if(this.gameSubState == this.SUBSTATES.playing) return false 

      if(this.gameSubState == this.SUBSTATES.attempting){
        if(this.playerAttempted) return true
        return false
      }

      return true
    },
    playerAttempted(){
      return this.playersAttempt.includes(this.myId)
    },
    playerAttemptIndex(){
      return this.playersAttempt.indexOf(this.myId)
    },
    currentPuzzleImage(){
      let roundIndex = this.round - 1
      roundIndex = roundIndex < 0 ? 0 : roundIndex

      // TODO Cambiar a tamaño que corresponda
      return this.options.puzzles[roundIndex].image.url
    },
    imageWidthPercent(){
      return 1 / (this.playerOrder.length+2) * 100
    },
    imageOffsetPercent(){
      return this.myPosition * this.imageWidthPercent
    },
    imageCrop(){
      let roundIndex = this.round - 1
      roundIndex = roundIndex < 0 ? 0 : roundIndex
      let image = this.options.puzzles[roundIndex].image;
      
      let imageCrop = 1 /  (this.playerOrder.length+2);

      let targetWidth = image.width * imageCrop
      let scaleProportion = window.innerWidth / targetWidth

      let objectPosition = targetWidth * this.myPosition * scaleProportion;
      
      return {width: targetWidth * scaleProportion, height: image.height * scaleProportion, objectPosition: `-${objectPosition}px 0`}
    },
    bgControllerUrl() {
      return this.options.controllerBackground ? 
        'url(' + encodeURI(
          this.options.controllerBackground.sizes.mobile.url ? 
          this.options.controllerBackground.sizes.mobile.url : 
          this.options.controllerBackground.url) + ')' 
          : 'url(/assets/trivia/background.png)'
    }

  },
  watch:{
    gameSubState(state){
      console.log(state)
      switch(state){
        case this.SUBSTATES.win:
          this.timerWebWorkers.postMessage({ pause: true })
          console.log("Game won !!")
          break;
        case this.SUBSTATES.lose:
          this.timerWebWorkers.postMessage({ pause: true })
          console.log("Time over. You lose");
          break;
        case this.SUBSTATES.playing:
          this.failed = false;
          break;
      }
    }
  },
  methods: {
    sync(){
      if(!this.DisableSyncButton) {
        this.$socket.client.emit("gameMessage", {type: "sync"})
      }
    },
    pauseWorkerTimer(){
      this.attemptWebWorkers.postMessage({ pause: true })
    }
  },
  sockets: {
    GameTimer(data){
      console.log("Received GameTimer", data)
      this.timerWebWorkers.postMessage({ endTime: data.tsGameOver , delay: 0 })

    },
    RoundStarted(data) {
      console.log("Received PlayerOrder", data)
      this.playerOrder = data.playerOrder;
      this.myPosition = this.playerOrder.indexOf(this.myId);
      this.round = data.round;
      this.maxRounds = data.maxRounds;
    },
    playersAttempt(data){

      this.playersAttempt = data.playersAttempt
      this.tsWillFinish = data.tsWillFinish

      if(!this.tsWillFinish) return 
      console.log('timestamp',this.tsWillFinish - Date.now())

      this.pauseWorkerTimer()
      this.attemptWebWorkers.postMessage({ endTime: Date.now() + this.options.attemptTimer * 1000 , delay: 0 })

    },
    RoundWon(){
      console.log("Round Won!!")
      this.pauseWorkerTimer()
      this.attemptCircle = 100
    },
    AttemptFail(){
      console.log("AttemptFail!")
      this.pauseWorkerTimer()
      this.attemptCircle = 100
      this.failed = true
    }
  },    
  mounted() {
    console.clear()
    this.attemptWebWorkers = new Worker(new URL("@/workers/CountdownWorker.js", import.meta.url));
    this.attemptWebWorkers.onmessage = ({ data }) => {
      this.attemptCircle = Math.floor(data.timeNormalized * 100)
    }

    this.timerWebWorkers = new Worker(new URL("@/workers/CountdownWorker.js", import.meta.url));
    this.timerWebWorkers.onmessage = ({ data }) => {
      this.gameTimer = {
        string: data.timeString,
        normalized: Math.floor(data.timeNormalized * 100)
      } 
    }
  }
};
</script>

<style scoped src="./PuzzleController.scss" lang="scss"> </style>

<style lang="scss">
  @import '@/styles/variables.scss';

  .playerlist {
    max-height: 120px;
    overflow-y: auto;
    border-top: solid 1px $grey-lighter;

    .playerAvatarContainer {
      width: 100%;

      &:not(:last-of-type) {
        border-bottom: solid 1px $grey-lighter;
        margin-block: .2em !important;
      }
  
      .username{
        flex: 1;
        width: -webkit-fill-available;
        text-align: left !important;
        padding-left: 0.5em;
        font-weight: bold;
      }
    }
  }
  </style>